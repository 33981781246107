export const PAYMENT_REQUEST_WIDGET_ID = '4425f8e8-51fb-457b-9123-fdb7b1cef94a';

export enum FedopsInteractions {
  ValidatePaymentInteraction = 'validate-payment-interaction',
  FetchPaymentRequest = 'fetch-payment-request',
  Pay = 'pay',
}
export const THANK_YOU_PAGE_PATH = 'thank-you';

export enum ErrorMessage {
  NOT_FOUND = 'not found',
  ERROR = 'error',
}

export enum summaryMobileViewState {
  SHOW = 'show',
  HIDE = 'hide',
}
